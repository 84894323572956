.switcher {
  input {
    &:checked {
      & + .switcher--toggle {
        background-color: rgb(79, 70, 229);

        &:before {
          background-color: #fff;
          left: calc(100% - 0.625rem);
        }
      }
    }
  }

  &--toggle {
    &:before {
      content: '';
      position: absolute;
      background-color: rgb(79, 70, 229);
      border-radius: 50%;
      width: 0.625rem;
      height: 0.625rem;
      top: 1px;
      left: 1px;
      transition: left cubic-bezier(0.4, 0, 0.2, 1) 0.15s,
        background-color cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
    }
  }
}
