.confirm-btn {
  &--dialog {
    top: auto;
    right: calc(100% + 8px);
    bottom: -4px;
    &:after {
      content: '';
      border-style: solid;
      border-width: 6px 0 6px 8px;
      border-color: transparent transparent transparent #fff;
      display: inline-flex;
      top: auto;
      bottom: 4px;
      position: absolute;
      left: 100%;
    }
  }
}
