.modern-checkbox {
  input {
    &:checked {
      & + span {
        background-color: rgb(79, 70, 229);
        color: #ffffff;
      }
    }
  }
}
